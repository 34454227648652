<template>
  <section>
    <section class="navBack">
      <v-container class="container-custom">
        <v-row>
          <v-col md="12" class="topNav">
            <ul class="navList">
              <li>Home</li>
              <li><v-icon> mdi-chevron-right </v-icon> Insurance</li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <v-container class="container-custom">
      <v-row>
        <v-col md="12">
          <h1>Coming Soon</h1>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "Insurance",
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.navList {
  padding-left: 0;
  list-style: none;
  display: flex;
  font-size: 14px;
  li {
    .v-icon {
      font-size: 18px;
      margin-left: 5px;
    }
    // margin-right: 15px;
  }
}
</style>